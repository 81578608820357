<template>
  <div class="cooperationFrameworkProtocol">
    <div class="content">
      <div class="title center bold">"村口壹站"加盟合作协议</div>
      <div class=" indent5">您为使用{{companyName}}（以下简称“村口科技”）的“村口壹站”品牌，服从村口科技的管理和辅导，在农村开办农村综合便民服务站（以下简称“服务站”），特签订此协议。</div>
      <div class=" indent5">第一章 合作内容</div>
      <div class=" indent5">您须根据本协议约定开办服务站,您在开办服务站业务中须严格按照村口科技及村口科技合作方制定的业务操作流程办理业务，且只可办理本协议约定业务以及根据后续签订的业务合作协议中明确可开展的业务（需经村口科技书面同意），包括但不限于：</div>
      <div class=" indent5">1、农村普惠金融服务;</div>
      <div class=" indent5">2、代缴费、代订票和代充值便民服务;</div>
      <div class=" indent5">3、物流快递服务;</div>
      <div class=" indent5">4、农村电商服务;</div>
      <div class=" indent5">5、就业咨询服务;</div>
      <div class=" indent5">6、健康医疗管理及咨询服务;</div>
      <div class=" indent5">7、公益类服务，以及村口科技审核认为您适合合作的其他服务项目。</div>
      <div class=" indent5">村口科技将按照本协议及后续签订的业务合作协议中相关考核办法规定的考核内容、计价方式及结算时间向您支付相应的服务费。双方合作为加盟合作关系，不构成劳动法律关系，村口科技也无义务缴纳社保等。</div>
      <div class=" indent5">第二章 合作期限</div>
      <div class=" indent5">您同意并确认，本协议的有效期为1年，如双方无异议，自动续期1年，续期次数不限。</div>
      <div class=" indent5">协议期间，如您欲提前终止本协议，需至少提前【3】个月向村口科技提出书面申请并获得同意。</div>
      <div class=" indent5">第三章 权利与义务</div>
      <div class=" indent5">1.村口科技的权利与义务</div>
      <div class=" indent5">1.1权利</div>
      <div class=" indent5">1.1.1村口科技有权自行制定服务站各项管理规定及考核办法，对您在服务站的服务及成果进行管理、检查和监督。</div>
      <div class=" indent5">1.1.2“村口壹站”品牌属于村口科技所有。村口科技合作方品牌属于村口科技合作方所有。</div>
      <div class=" indent5">1.1.3物资：在本协议签署之后，村口科技或协调其合作方向您提供物资及专用的机具耗材、业务宣传资料供您作为开展本协议所涉业务使用。如果需要缴纳押金的，具体金额以具体业务合作协议为准。</div>
      <div class=" indent5">村口科技提供的设备、物资的所有权归村口科技所有；村口科技合作方提供的设备、物资的所有权归村口科技合作方所有；管理、监督权归村口科技所有；使用权归您所有，您应以合理的谨慎和注意确保您提供服务过程中不对自己和他人的人身、财产等权益造成损害；如造成损害的，相关风险和责任由您自行承担。</div>
      <div class=" indent5">1.2义务</div>
      <div class=" indent5">1.2.1村口科技将协助您取得村口科技合作方授权开展专项业务的许可。</div>
      <div class=" indent5">1.2.2村口科技及村口科技合作方提供本协议本章1.1.3中约定的设备、物资。</div>
      <div class=" indent5">1.2.3村口科技负责协调其合作方依照本协议约定提供本协议约定的业务协助。</div>
      <div class=" indent5">2.您的权利与义务</div>
      <div class=" indent5">2.1权利</div>
      <div class=" indent5">2.1.1获得村口科技、村口科技合作方的协助，包括但不限于：</div>
      <div class=" indent5">2.1.1.1本协议本章1.1.3中村口科技合作方所提供设备、物资的使用权。</div>
      <div class=" indent5">2.1.1.2本协议本章1.2.3中村口科技、村口科技合作方所提供的协助。</div>
      <div class=" indent5">2.1.2根据业务合作协议约定开展专项业务。</div>
      <div class=" indent5">2.1.3获取相关考核办法所约定的服务费。</div>
      <div class=" indent5">2.1.4本协议到期后，在同等条件下，您拥有优先续签合作协议的权利。</div>
      <div class=" indent5">2.2义务</div>
      <div class=" indent5">2.2.1您须遵守并执行村口科技各项管理规定及考核办法。</div>
      <div class=" indent5">2.2.2您须在本协议签订之日起10日内，提供符合实用面积不小于10平方米的经营专用场所及提供服务所必备的基础设施、办公用具（包括但不限于网络、宽带、水电、桌椅等）、并负责场所内的安保工作（包括但不限于：口头提示、告知牌提示，及保卫工作等）（经营场所非您所有的，您需提供经营场所的租赁协议或其他证明材料，原则上您经营场所的租赁时间不得低于和村口科技签订本协议约定的合作期限。自有产权需提供相关产权证明或当地政府出具的法律文书）。</div>
      <div class=" indent5">合作期内，您须按照村口科技的要求进行服务站布置，负责服务站专用场所的日常维护，保证不挪作它用，并自行承担涉及服务站范围内所造成自己或他人损失的一切赔偿责任。您理解并同意，为项目实施需要，村口科技有权对专用场所内的功能划分、装修布置等提出要求，您应遵照执行。</div>
      <div class=" indent5">2.2.3您务必做到对本章1.1.3条所涉及设备、物资妥善保管及合理使用，如造成无法正常使用(包括但不限于您的故意损坏、过失损坏、保管使用不当等原因），则您应在3日内通知村口科技，由村口科技工作人员负责将设备恢复可使用状态，包括但不仅限于维修、更换等，因此产生的一切费用及合理支出由您负责承担。本协议终止后15日内，您应立即交还上述物资，如发生遗失、损坏等应照原价赔偿。同时，除质量问题外，保管使用期间，您应自行承担前述物资的使用安全责任（包括但不限于因使用前述设备造成自身或他人人身或财产损害的责任）。</div>
      <div class=" indent5">2.2.4您须负责服务站信息保密工作，包括但不限于：服务站业务策略信息、客户、合作方信息及相关交易信息等。</div>
      <div class=" indent5">2.2.5您须按时接受村口科技、村口科技合作方提供的本章中1.2.3协助，如需收费，村口科技将事先告知。</div>
      <div class=" indent5">2.2.6您须自行负责在经营活动中由您引起和造成的一切法律问题。（包括但不限于：行政部门的调查、处罚等，以及己方及他方的人身损害责任。）</div>
      <div class=" indent5">2.2.7在本协议期间，未经村口科技的书面许可，您不得从事与本协议所涉业务存在竞争关系或类似的业务或为其他第三方提供与本协议相同或近似的服务或从事本协议约定业务以及根据后续签订的业务合作协议中未明确的业务。</div>
      <div class=" indent5">第四章 服务费结算和支付</div>
      <div class=" indent5">1.根据相关法律法规规定，由您承担纳税义务，您应保管相应的票据。</div>
      <div class=" indent5">2.费用结算时，您必须事先向村口科技提供正规合格的发票，或按照您选择确认并符合国家税法等相关法律规定方式，由村口科技代扣代缴相关税费后，才会予以支付相关费用。</div>
      <div class=" indent5">3.村口科技在隔月25日前（如遇特殊情况，以村口科技最新通知为准）向您支付或代为支付上个结算周期（如无特殊约定，每一个自然月（或季度）作为一个结算周期）的全部费用（如遇节假日则顺延发放），相关费用将支付至村口科技指定的您在村口科技合作方开立的银行账户。</div>
      <div class=" indent5">4.针对普惠金融服务业务，您理解并同意若合作方银行延期支付服务费，村口科技及村口科技合作方则顺延支付相应费用。</div>
      <div class=" indent5">5.您理解并同意，服务费、激励等相关计算，以村口科技及村口科技合作方系统记录的相关交易记录、金额、时间等数据为准，您对此不持异议；相关费用支付后，村口科技仍有权就相关数据进行复核，并有权自行从您的未结算款项扣除差额部分。</div>
      <div class=" indent5">6.您理解并同意，您与村口科技应根据适用的法律分别承担各自的税负义务及代扣代缴义务。</div>
      <div class=" indent5">第五章 违约与解除</div>
      <div class=" indent5">1.协议期内，当您出现以下情形的，村口科技有权以单方通知形式立即终止本协议，由此造成的损失，村口科技有权从您的服务费中扣除，超过的部分，村口科技有权继续追偿：</div>
      <div class=" indent5">1.1发生村口科技相关管理制度和后续签订的补充协议中明确标注禁止的行为。</div>
      <div class=" indent5">1.2发生违反《合规经营承诺书》（具体见业务合作协议）任意一条条款的行为。</div>
      <div class=" indent5">1.3您不能完成村口科技制定的相关考核办法的基础指标。</div>
      <div class=" indent5">1.4村口科技、村口科技合作方抽查巡视发现有不诚信行为或【1】个季度内被服务客户有效投诉达【3】次及以上。</div>
      <div class=" indent5">1.5因涉嫌犯罪被采取强制措施或被追究刑事责任的。</div>
      <div class=" indent5">1.6在与村口科技、村口科技合作方合作的过程中，同时从事与本协议所涉业务存在竞争关系或类似的业务或为其他第三方提供与本协议相同或近似的服务。</div>
      <div class=" indent5">1.7您有其他违反本协议或管理办法之情形，经村口科技书面通知后【10】个工作日内未有改善的。</div>
      <div class=" indent5">2.您理解并同意，因您提前解除本协议或出现上述可能导致本协议终止的违约情形时，村口科技将暂停支付结算费用，您应配合处理相关解约事宜，具体包括但不限于：</div>
      <div class=" indent5">2.1 您应依照村口科技指示于协议结束15日内返还全部本协议第三章1.1.3条所涉物资、设备，如有损坏或遗失，您应履行本协议第三章2.2.3之义务。</div>
      <div class=" indent5">2.2 您应立即停止服务站全部业务，并依照村口科技、村口科技合作方要求处理相关资料回收或销毁等相关事宜。</div>
      <div class=" indent5">2.3 若在本协议结算日您还有未完结的服务内容，您应继续承担该服务责任，直至服务提供完毕，并将相关情况及时告知村口科技及村口科技合作方。</div>
      <div class=" indent5">2.4 因您提前解除协议或违约给村口科技、村口科技合作方造成损失的，您应负责赔偿。</div>
      <div class=" indent5">3.因不可抗力因素（如战争，地震，瘟疫，动乱，国家政策有重大变化等），造成双方无法继续执行本协议时，本协议自行终止，双方互不承担违约责任。</div>
      <div class=" indent5">4.本协议期间及终止后两年内，未经村口科技的书面许可，您不得从事与村口科技业务相竞争或类似的业务或为其他第三方提供与本协议相同或近似的服务。</div>
      <div class=" indent5">第六章 其他</div>
      <div class=" indent5">1.村口科技所制定的各项经营管理办法、考核标准、考核方案、附件与本协议具同等法律效力。</div>
      <div class=" indent5">2.纠纷解决</div>
      <div class=" indent5">因本协议发生的争议，由双方协商解决；协商不成，任何一方有权向上海仲裁委员会提请仲裁。</div>
    </div>
    <div class="footer" v-show="signatureShow">
      <van-button type="info" color="#3F7C53" @click="signatureClick">签名</van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant'
import common from '@/utils/common'
import {COMPANY_NAME} from '@/utils/constantNum'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Button.name]: Button
  },
  data() {
    return {
      companyName:COMPANY_NAME,
      signatureShow: false,
      agreementParams: {},
      exampleStr:'举个栗子'
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.signatureShow = this.$route.query.sign == 'sign' ? true : false
      if(this.$route.query.agreementParams){
        this.agreementParams = JSON.parse(this.$route.query.agreementParams)
      }
    })
    uwStatisticAction('/agreement/cooperationFrameworkProtocol','“村口壹站”加盟合作协议')
  },
  methods: {
    signatureClick() {
      this.$router.push({name:'signatureFour', query:{stationNo: this.$route.query.stationNo}})

    }
  }
}
</script>

<style lang="less" scoped>
.cooperationFrameworkProtocol{
   background: #f5f5f5;
  font-size: 0.3rem;
  box-sizing: border-box;
  padding: 20px 0.3rem 100px 0.3rem ;
  -webkit-overflow-scrolling: touch;
  .content{
    .title{
      font-size: 0.4rem;
    }
    .bold{
      font-weight: bold
    }
    .center{
      text-align: center;
    }
    .indent5{
      text-indent: 0.6rem;
    }
    .mt3{
      margin-top: 0.3rem;
    }
    .underline{
      text-decoration: underline;
    }
    .rightText{
      text-align: right;
    }
    .grayColor{
      background-color: #cccccc;
    }
  }
  .footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .van-button{
      width: 100%;
    }
  }
}
</style>
